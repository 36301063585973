// import React from 'react';
import './styling.css'; // Import your CSS file
import logoImage from './images/logo-removebg-preview.png';
import courseImage1 from './images/whatsapp-e-commerce.jpg';
import courseImage2 from './images/ck53ob2ex00ctqtfzg7j42crs-whatsapp-for-ecommerce.one-half.png';
import courseImage3 from './images/doorstep-parcel-flat-illustration-of-parcel-delivery-vector.jpg';
import Grocery from './images/centurion-gauteng-south-africa-july-260nw-2484652117.jpg';
import meats from './images/assortment-meat-seafood-beef-chicken-fish-pork-assortment-meat-seafood-149111241.jpg';
import vegitables from './images/2-2-2-2foodgroups_vegetables_detailfeature.jpg';
import testimonial1 from './images/user2.jpg';
import testimonial2 from './images/user3.jpg';

function App() {
  const showMenu = () => {
    document.getElementById("navLinks").style.right = "0";
  };

  const hideMenu = () => {
    document.getElementById("navLinks").style.right = "-200px";
  };

  return (
    <div>
      {/* Header Section */}
      <section className="header">
        <nav>
          <a href="#"><img src={logoImage} alt="Logo" /></a>
          <div className="nav-links" id="navLinks">
            <i className="fa fa-times" onClick={hideMenu}></i>
            <ul>
              <li><a href="#">HOME</a></li>
              <li><a href="#">ABOUT</a></li>
              <li><a href="#">COURSE</a></li>
              <li><a href="#">BLOG</a></li>
              <li><a href="#">CONTACT</a></li>
            </ul>
          </div>
          <i className="fa fa-bars" onClick={showMenu}></i>
        </nav>

        <div className="text-box">
          <a href="#" className="hero-btn">Visit Us To Know More</a>
        </div>
        <div className="wave-container">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#fff" fillOpacity="1" d="M0,96L48,133.3C96,171,192,245,288,272C384,299,480,277,576,240C672,203,768,149,864,144C960,139,1056,181,1152,197.3C1248,213,1344,203,1392,197.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
          </svg>
        </div>
      </section>

      {/* Course Section */}
      <section className="Course">
        <h1>How it works</h1>
        <div className="row">
          <div className="course-col">
            <h3>Open your Whatsapp</h3>
            <img src={courseImage1} alt="Intermediate Course" />
          </div>
          <div className="course-col">
            <h3>grab your producs in to cart</h3>
            <img src={courseImage2} alt="Degree Course" />
          </div>
          <div className="course-col">
            <h3>We deliver at your at doorStep</h3>
            <img src={courseImage3} alt="Post Graduation Course" />
          </div>
        </div>
      </section>

      {/* Campus Section */}
      <section className="campus">
        <h1>We are Glad to Serve You</h1>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
        <div className="row">
          <div className="campus-col">
          <img src={Grocery} alt="Post Graduation Course" />
            <div className="layer">
              <h3>Groceries</h3>
            </div>
          </div>
          <div className="campus-col">
          <img src={meats} alt="Post Graduation Course" />
            <div className="layer">
              <h3>Meats</h3>
            </div>
          </div>
          <div className="campus-col">
          <img src={vegitables} alt="Post Graduation Course" />
            <div className="layer">
              <h3>Vegetables</h3>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h1>What Our Student Says</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        <div className="row">
          <div className="testimonials-col">
          <img src={testimonial1} alt="Post Graduation Course" />
            <div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <h3>Hisham</h3>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-regular fa-star"></i>
            </div>
          </div>
          <div className="testimonials-col">
          <img src={testimonial2} alt="Post Graduation Course" />
            <div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <h3>Shibu</h3>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star-half-stroke"></i>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      {/* <section className="footer">
        <div className="footer-row">
          <div className="footer-col">
            <h4>Info</h4>
            <ul className="links">
              <li><a href="#">About Us</a></li>
              <li><a href="#">Compressions</a></li>
              <li><a href="#">Customers</a></li>
              <li><a href="#">Service</a></li>
              <li><a href="#">Collection</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Explore</h4>
            <ul className="links">
              <li><a href="#">Free Designs</a></li>
              <li><a href="#">Latest Designs</a></li>
              <li><a href="#">Themes</a></li>
              <li><a href="#">Popular Designs</a></li>
              <li><a href="#">Art Skills</a></li>
              <li><a href="#">New Uploads</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Legal</h4>
            <ul className="links">
              <li><a href="#">Customer Agreement</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">GDPR</a></li>
              <li><a href="#">Security</a></li>
              <li><a href="#">Testimonials</a></li>
              <li><a href="#">Media Kit</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Newsletter</h4>
            <p>
              Subscribe to our newsletter for a weekly dose of news, updates, helpful tips, and exclusive offers.
            </p>
            <form action="#">
              <input type="text" placeholder="Your email" required />
              <button type="submit">SUBSCRIBE</button>
            </form>
            <div className="icons">
              <i className="fa-brands fa-facebook-f"></i>
              <i className="fa-brands fa-twitter"></i>
              <i className="fa-brands fa-linkedin"></i>
              <i className="fa-brands fa-github"></i>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default App;
